<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row>
        <invoice />

        <invoice-actions />
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import Invoice from "./components/invoice";
import InvoiceActions from "./components/invoiceActions";

export default {
  components: {
    BRow,
    BCol,
    InvoiceActions,
    Invoice,
  },
};
</script>
