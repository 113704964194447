<template>
  <b-col cols="12" xl="8">
    <b-card id="invoice" class="card border-0 hp-invoice-card">
      <b-row align-h="between">

        <b-col cols="12" lg="6">
          <p class="h1 text-black-100" style="white-space: nowrap;">Detail Transaksi</p>
          <p class="hp-p1-body mb-16">
            Transaksi Nomor <span class="text-danger-1">#125863478945</span>
          </p>
        </b-col>

        <div class="divider"></div>

        <b-col cols="12">
          <p class="text-black-60 hp-p1-body">Informasi Klien</p>
          <p>Edison Maryadi</p>
          <p>edisonmaryadi@user.com</p>
        </b-col>
      </b-row>

      <div class="divider"></div>

      <b-row>
        <b-col cols="12">
          <b-table-simple small caption-top responsive borderless>
            <b-thead>
              <b-tr>
                <b-th scope="col" class="pt-0 pl-0 pb-18 bg-transparent">
                  Gol. Darah
                </b-th>
                <b-th
                    scope="col"
                    class="pt-0 pb-18 bg-transparent hp-invoice-table-desc"
                >
                  Jumlah Bloodbags
                </b-th>
                <b-th
                    scope="col"
                    class="pt-0 pl-0 pb-18 bg-transparent hp-invoice-table-th"
                >
                  Biaya
                </b-th>
                <b-th
                    scope="col"
                    class="pt-0 px-0 pb-18 bg-transparent hp-invoice-table-th text-center"
                >
                  Harga
                </b-th>
              </b-tr>
            </b-thead>

            <b-tbody>
              <b-tr>
                <b-td class="py-6 pl-0">
                  <p>O+</p>
                </b-td>
                <b-td class="py-6">
                  <p>20</p>
                </b-td>
                <b-td class="py-6 pl-0">
                  <p>Rp. 10.000</p>
                </b-td>
                <b-td class="py-6 px-0 text-center">
                  <p>Rp. 200.000</p>
                </b-td>
              </b-tr>

              <b-tr>
                <b-td class="py-6 pl-0">
                  <p>O+</p>
                </b-td>
                <b-td class="py-6">
                  <p>20</p>
                </b-td>
                <b-td class="py-6 pl-0">
                  <p>Rp. 10.000</p>
                </b-td>
                <b-td class="py-6 px-0 text-center">
                  <p>Rp. 200.000</p>
                </b-td>
              </b-tr>

            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <div class="divider"></div>

      <b-row align-h="end" class="mr-0">
        <b-col cols="12" xl="3" class="pb-16 hp-print-checkout">
          <b-row>
            <div class="divider"></div>
          </b-row>

          <b-row align-v="center" align-h="between">
            <h5 class="text-danger-1 hp-flex-none w-auto">Total</h5>
            <h5 class="text-danger-1 hp-flex-none w-auto">Rp. 400.000</h5>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCard,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCard,
  },
};
</script>
